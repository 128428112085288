import React from 'react'
import {Link} from 'gatsby'
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from '../components/Common/PageBanner'
import Footer from "../components/App/Footer"
import details1 from '../assets/images/services/service3.png'
import project2 from '../assets/images/projects/project2.jpg'
import charts from '../assets/images/services/charts.jpg'
import icon1 from '../assets/images/services/service-icon1.png'
import icon2 from '../assets/images/services/service-icon2.png'
import icon3 from '../assets/images/services/service-icon3.png'
 
const Details = () => {
    return (
        <Layout>
            <Navbar />
            <PageBanner
                pageTitle="APP development" 
                homePageText="Services" 
                homePageUrl="/services" 
                activePageText="APP development" 
            />
            <ServiceDetailsContent />
            <RelatedProjects />
            <Footer />
        </Layout>
    );
}

const ServiceDetailsContent = () => {
    return (
        <section className="services-details-area ptb-100">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 col-md-12">
                        <div className="services-details-image">
                            <img src={details1} alt="about" />
                        </div>

                        <div className="services-details-desc">
                            <span className="sub-title">APP Development</span>
                            <h3>Web platform and mobile app</h3>
                            <p>We create web and mobile apps to spread your projects, engage your stakeholders, exchange information and manage data.</p>

                            <div className="row align-items-center">
                                <div className="col-lg-6 col-md-6">
                                    <div className="image">
                                        <img src={project2} alt="about" />
                                    </div>
                                </div>

                                <div className="col-lg-6 col-md-6">
                                    <div className="content">
                                        <h3>Features</h3>
                                        <ul>
                                        <li>Involve stakeholders</li>
                                            <li>Make your outcomes visible</li>
                                            <li>Inform the general public</li>
                                            <li>Collect data</li>
                                            <li>Capitalize knowledge</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                            <p>Dedicated app development, web or mobile, allows you to build tools that are very specific to the needs of an organization or of an individual project and to respond convincingly to user needs. Our apps adapt well to the characteristics of any context, even the most technologically vulnerable ones, with loose or absent connections. </p>
                            <h3>Technologies</h3>
                            <ul className="technologies-features">
                                <li><span>Angular</span></li>
                                <li><span>Material</span></li>
                                <li><span>Typescript</span></li>
                                <li><span>JavaScript</span></li>
                                <li><span>Capacitor</span></li>
                                <li><span>Ionic</span></li>
                                <li><span>Android SDK</span></li>
                                <li><span>iOS SDK</span></li>
                                <li><span>React Native</span></li>
                            </ul>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                        <ServiceSidebar />
                    </div>
                </div>
            </div>
        </section>
    )
}

const ServiceSidebar = () => {
    return (
        <div className="services-details-info">
            <ul className="services-list">
                <li>
                    <Link to="/app-development" className="active">
                       App development
                    </Link>
                </li>
                <li>
                    <Link to="/digital-transformation">
                        Digital transformation
                    </Link>
                </li>
                <li>
                    <Link to="/social-balance-monitoring-evaluation">
                        Social balance, monitoring and evaluation
                    </Link>
                </li>
                <li>
                    <Link to="/case-management">
                    Case management
                    </Link>
                </li>
                <li>
                    <Link to="/data-analysis">
                        Data Analysis
                    </Link>
                </li>
                <li>
                    <Link to="/artificial-intelligence">
                        Artificial intelligence
                    </Link>
                </li>
            </ul>
        </div>
    )
}

const RelatedProjects = () => {
    return (
        <section className="services-area pt-100 pb-70 bg-f1f8fb">
            <div className="container">
                <div className="section-title">
                    <h2>Developed projects</h2>
                </div>

                <div className="row">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box ">
                            <div className="icon">
                                <img src={icon1} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/c2s   ">
                                    Cheese2School
                                </Link>
                            </h3>
                            <p>Mobile app to collect school data attendance via image recognition</p>

                            <Link to="/project/c2s" className="read-more-btn">
                            Read more <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/cpa-web-platform">
                                    CPA Initiative
                                </Link>
                            </h3>
                            <p>WWGVC app to interact with CPA Initiative platform</p>
                            
                            <Link to="/project/cpa-web-platform" className="read-more-btn">
                                Read more<i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div> 
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/irhis">
                                    iRHIS
                                </Link>
                            </h3>
                            <p>Web and mobile APP to manage UNHCR health services</p>
                            
                            <Link to="/project/irhis" className="read-more-btn">
                                Scopri di più <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>    
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/lis-eis">
                                    LIS / EIS
                                </Link>
                            </h3>
                            <p>Livelihood and Energy webapps in UNHCR camps</p>
                            
                            <Link to="/project/lis-eis" className="read-more-btn">
                                Read more <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div> 
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/water-trucking-software">
                                    Water Trucking software
                                </Link>
                            </h3>
                            <p>Acted platform to manage water distrubution.</p>
                            
                            <Link to="/project/water-trucking-software" className="read-more-btn">
                                Read more <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>  
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/drr">
                                    DRR YAGO
                                </Link>
                            </h3>
                            <p>WEBGIS for natural disaster risk reduction in Central America</p>
                            
                            <Link to="/project/drr" className="read-more-btn">
                                Read more<i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="single-services-box">
                            <div className="icon">
                                <img src={icon2} alt="about" />
                            </div>
                            <h3>
                                <Link to="/project/tu6scuola">
                                    #tu6scuola
                                </Link>
                            </h3>
                            <p>The Cinegame as an alternative activity to reduce school drop-outs</p>
                            
                            <Link to="/project/tu6scuola" className="read-more-btn">
                                Read more <i className="flaticon-right"></i>
                            </Link>
                        </div>
                    </div> 
                </div>
            </div>
        </section>
    )
}

export default Details
